export enum AV2HasAccessAdditional {
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  NOT_CHECK = 'NOT_CHECK'
}

export enum AV2MessagesViewPermissionEnum {
  READ_MESSAGES_VIEW_ROLE = 'READ_MESSAGES_VIEW_ROLE',
  CREATE_MESSAGES_VIEW_ROLE = 'CREATE_MESSAGES_VIEW_ROLE'
}

export enum AV2PermissionsViewPermissionsEnum {
  PERMISSIONS_VIEW_ROLE = 'PERMISSIONS_VIEW_ROLE',
  PERMISSION_DELETE_FORM_VIEW_ROLE = 'PERMISSION_DELETE_FORM_VIEW_ROLE',
  PERMISSION_EDIT_FORM_VIEW_ROLE = 'PERMISSION_EDIT_FORM_VIEW_ROLE',
  PERMISSION_CREATE_FORM_VIEW_ROLE = 'PERMISSION_CREATE_FORM_VIEW_ROLE'
}

export enum AV2ClientUsersViewPermissionsEnum {
  CLIENT_USERS_VIEW_ROLE = 'CLIENT_USERS_VIEW_ROLE',
  CLIENT_USER_SESSION_AUTO_REFRESH_VIEW_ROLE = 'CLIENT_USER_SESSION_AUTO_REFRESH_VIEW_ROLE'
}

export enum AV2UserPermissionsEnum {
  IMPERSONATE_ROLE = 'IMPERSONATE_ROLE'
}

export enum AV2SettingsViewPermissionsEnum {
  PROFILE_VIEW_ROLE = 'PROFILE_VIEW_ROLE',
  PROFILE_EDIT_FORM_VIEW_ROLE = 'PROFILE_EDIT_FORM_VIEW_ROLE',
  TRANSLATIONS_VIEW_ROLE = 'TRANSLATIONS_VIEW_ROLE',
  TRANSLATION_EDIT_FORM_VIEW_ROLE = 'TRANSLATION_EDIT_FORM_VIEW_ROLE',
  TRANSLATION_CREATE_FORM_VIEW_ROLE = 'TRANSLATION_CREATE_FORM_VIEW_ROLE',
  TRANSLATION_DELETE_FORM_VIEW_ROLE = 'TRANSLATION_DELETE_FORM_VIEW_ROLE',
  CURRENCIES_VIEW_ROLE = 'CURRENCIES_VIEW_ROLE',
  CURRENCY_CREATE_FORM_VIEW_ROLE = 'CURRENCY_CREATE_FORM_VIEW_ROLE',
  CURRENCY_EDIT_FORM_VIEW_ROLE = 'CURRENCY_EDIT_FORM_VIEW_ROLE',
  CURRENCY_DELETE_FORM_VIEW_ROLE = 'CURRENCY_DELETE_FORM_VIEW_ROLE',
  PRICE_LISTS_VIEW_ROLE = 'PRICE_LISTS_VIEW_ROLE',
  PRICE_LIST_CREATE_FORM_VIEW_ROLE = 'PRICE_LIST_CREATE_FORM_VIEW_ROLE',
  PRICE_LIST_EDIT_FORM_VIEW_ROLE = 'PRICE_LIST_EDIT_FORM_VIEW_ROLE',
  PRICE_LIST_DELETE_FORM_VIEW_ROLE = 'PRICE_LIST_DELETE_FORM_VIEW_ROLE',
  LICENSE_TYPES_VIEW_ROLE = 'LICENSE_TYPES_VIEW_ROLE',
  LICENSE_TYPE_CREATE_FORM_VIEW_ROLE = 'LICENSE_TYPE_CREATE_FORM_VIEW_ROLE',
  LICENSE_TYPE_EDIT_FORM_VIEW_ROLE = 'LICENSE_TYPE_EDIT_FORM_VIEW_ROLE',
  LICENSE_TYPE_DELETE_FORM_VIEW_ROLE = 'LICENSE_TYPE_DELETE_FORM_VIEW_ROLE',
  PRODUCTS_VIEW_ROLE = 'PRODUCTS_VIEW_ROLE',
  PRODUCT_CREATE_FORM_VIEW_ROLE = 'PRODUCT_CREATE_FORM_VIEW_ROLE',
  PRODUCT_EDIT_FORM_VIEW_ROLE = 'PRODUCT_EDIT_FORM_VIEW_ROLE',
  PRODUCT_DELETE_FORM_VIEW_ROLE = 'PRODUCT_DELETE_FORM_VIEW_ROLE',
  ALARM_CATEGORIES_VIEW_ROLE = 'ALARM_CATEGORIES_VIEW_ROLE',
  ALARM_CATEGORY_CREATE_FORM_VIEW_ROLE = 'ALARM_CATEGORY_CREATE_FORM_VIEW_ROLE',
  ALARM_CATEGORY_EDIT_FORM_VIEW_ROLE = 'ALARM_CATEGORY_EDIT_FORM_VIEW_ROLE',
  ALARM_CATEGORY_DELETE_FORM_VIEW_ROLE = 'ALARM_CATEGORY_DELETE_FORM_VIEW_ROLE',
  ALARM_TYPES_VIEW_ROLE = 'ALARM_TYPES_VIEW_ROLE',
  ALARM_TYPE_EDIT_FORM_VIEW_ROLE = 'ALARM_TYPE_EDIT_FORM_VIEW_ROLE',
  ALARM_TYPES_TEMPLATES_VIEW_ROLE = 'ALARM_TYPES_TEMPLATES_VIEW_ROLE',
  ALARM_TYPE_TEMPLATE_EDIT_FORM_VIEW_ROLE = 'ALARM_TYPE_TEMPLATE_EDIT_FORM_VIEW_ROLE',
  EVENTS_CONFIGURATION_VIEW_ROLE = 'EVENTS_CONFIGURATION_VIEW_ROLE',
  EVENT_CONFIGURATION_CREATE_FORM_VIEW_ROLE = 'EVENT_CONFIGURATION_CREATE_FORM_VIEW_ROLE',
  EVENT_CONFIGURATION_EDIT_FORM_VIEW_ROLE = 'EVENT_CONFIGURATION_EDIT_FORM_VIEW_ROLE',
  EVENT_CONFIGURATION_DELETE_FORM_VIEW_ROLE = 'EVENT_CONFIGURATION_DELETE_FORM_VIEW_ROLE',
  EVENTS_TEMPLATES_VIEW_ROLE = 'EVENTS_TEMPLATES_VIEW_ROLE',
  EVENT_TEMPLATE_CREATE_FORM_VIEW_ROLE = 'EVENT_TEMPLATE_CREATE_FORM_VIEW_ROLE',
  EVENT_TEMPLATE_EDIT_FORM_VIEW_ROLE = 'EVENT_TEMPLATE_EDIT_FORM_VIEW_ROLE',
  EVENT_TEMPLATE_DELETE_FORM_VIEW_ROLE = 'EVENT_TEMPLATE_DELETE_FORM_VIEW_ROLE',
  TASK_TEMPLATES_VIEW_ROLE = 'TASK_TEMPLATES_VIEW_ROLE',
  TASK_TEMPLATE_CREATE_FORM_VIEW_ROLE = 'TASK_TEMPLATE_CREATE_FORM_VIEW_ROLE',
  TASK_TEMPLATE_EDIT_FORM_VIEW_ROLE = 'TASK_TEMPLATE_EDIT_FORM_VIEW_ROLE',
  TASK_TEMPLATE_DELETE_FORM_VIEW_ROLE = 'TASK_TEMPLATE_DELETE_FORM_VIEW_ROLE',
  API_ACCESS_VIEW_ROLE = 'API_ACCESS_VIEW_ROLE',
  API_ACCESS_CREATE_FORM_VIEW_ROLE = 'API_ACCESS_CREATE_FORM_VIEW_ROLE',
  API_ACCESS_DELETE_FORM_VIEW_ROLE = 'API_ACCESS_DELETE_FORM_VIEW_ROLE',
  RETRANSMISSION_API_ACCESS_VIEW_ROLE = 'RETRANSMISSION_API_ACCESS_VIEW_ROLE',
  RETRANSMISSION_API_ACCESS_CREATE_FORM_VIEW_ROLE = 'RETRANSMISSION_API_ACCESS_CREATE_FORM_VIEW_ROLE',
  RETRANSMISSION_API_ACCESS_DELETE_FORM_VIEW_ROLE = 'RETRANSMISSION_API_ACCESS_DELETE_FORM_VIEW_ROLE',
  EMAIL_CONFIG_VIEW_ROLE = 'EMAIL_CONFIG_VIEW_ROLE',
  EMAIL_CONFIG_EDIT_FORM_VIEW_ROLE = 'EMAIL_CONFIG_EDIT_FORM_VIEW_ROLE',
  EMAIL_SENDING_CONFIG_VIEW_ROLE = 'EMAIL_SENDING_CONFIG_VIEW_ROLE',
  EMAIL_SENDING_CONFIG_EDIT_FORM_VIEW_ROLE = 'EMAIL_SENDING_CONFIG_EDIT_FORM_VIEW_ROLE',
  SMS_CONFIG_VIEW_ROLE = 'SMS_CONFIG_VIEW_ROLE',
  SMS_CONFIG_EDIT_FORM_VIEW_ROLE = 'SMS_CONFIG_EDIT_FORM_VIEW_ROLE',
  REPORT_EMAIL_MESSAGE_CONFIG_VIEW_ROLE = 'REPORT_EMAIL_MESSAGE_CONFIG_VIEW_ROLE',
  REPORT_EMAIL_MESSAGE_CONFIG_EDIT_FORM_VIEW_ROLE = 'REPORT_EMAIL_MESSAGE_CONFIG_EDIT_FORM_VIEW_ROLE',
  ALARM_EMAIL_MESSAGE_CONFIG_VIEW_ROLE = 'ALARM_EMAIL_MESSAGE_CONFIG_VIEW_ROLE',
  ALARM_EMAIL_MESSAGE_CONFIG_EDIT_FORM_VIEW_ROLE = 'ALARM_EMAIL_MESSAGE_CONFIG_EDIT_FORM_VIEW_ROLE',
  ALARM_SMS_MESSAGE_CONFIG_VIEW_ROLE = 'ALARM_SMS_MESSAGE_CONFIG_VIEW_ROLE',
  ALARM_SMS_MESSAGE_CONFIG_EDIT_FORM_VIEW_ROLE = 'ALARM_SMS_MESSAGE_CONFIG_EDIT_FORM_VIEW_ROLE'
}

export enum AV2ClientsViewPermissionsEnum {
  CLIENTS_VIEW_ROLE = 'CLIENTS_VIEW_ROLE',
  CLIENT_OVERVIEW_ROLE = 'CLIENT_OVERVIEW_ROLE',
  CLIENT_EDIT_FORM_VIEW_ROLE = 'CLIENT_EDIT_FORM_VIEW_ROLE',
  CLIENT_CREATE_FORM_VIEW_ROLE = 'CLIENT_CREATE_FORM_VIEW_ROLE',
  CLIENT_EDIT_LOGO_FORM_VIEW_ROLE = 'CLIENT_EDIT_LOGO_FORM_VIEW_ROLE',
  CLIENT_DELETE_FORM_VIEW_ROLE = 'CLIENT_DELETE_FORM_VIEW_ROLE',
  CLIENT_CONTACTS_VIEW_ROLE = 'CLIENT_CONTACTS_VIEW_ROLE',
  CLIENT_CONTACT_OVERVIEW_VIEW_ROLE = 'CLIENT_CONTACT_OVERVIEW_VIEW_ROLE',
  CLIENT_CONTACT_EDIT_FORM_VIEW_ROLE = 'CLIENT_CONTACT_EDIT_FORM_VIEW_ROLE',
  CLIENT_CONTACT_CREATE_FORM_VIEW_ROLE = 'CLIENT_CONTACT_CREATE_FORM_VIEW_ROLE',
  CLIENT_CONTACT_DELETE_FORM_VIEW_ROLE = 'CLIENT_CONTACT_DELETE_FORM_VIEW_ROLE'
}

export enum AV2EmployeesViewPermissionsEnum {
  EMPLOYEES_VIEW_ROLE = 'EMPLOYEES_VIEW_ROLE',
  EMPLOYEE_CREATE_FORM_VIEW_ROLE = 'EMPLOYEE_CREATE_FORM_VIEW_ROLE',
  EMPLOYEE_WORK_TIME_REPORT_VIEW_ROLE = 'EMPLOYEE_WORK_TIME_REPORT_VIEW_ROLE',
  EMPLOYEE_EDIT_FORM_VIEW_ROLE = 'EMPLOYEE_EDIT_FORM_VIEW_ROLE',
}

export enum AV2CompaniesViewPermissionsEnum {
  COMPANIES_VIEW_ROLE = 'COMPANIES_VIEW_ROLE',
  COMPANY_CREATE_FORM_VIEW_ROLE = 'COMPANY_CREATE_FORM_VIEW_ROLE',
  COMPANY_EDIT_FORM_VIEW_ROLE = 'COMPANY_EDIT_FORM_VIEW_ROLE',
  COMPANY_DELETE_FORM_VIEW_ROLE = 'COMPANY_DELETE_FORM_VIEW_ROLE',
  COMPANY_CONTACTS_VIEW_ROLE = 'COMPANY_CONTACTS_VIEW_ROLE',
  COMPANY_CONTACT_OVERVIEW_VIEW_ROLE = 'COMPANY_CONTACT_OVERVIEW_VIEW_ROLE',
  COMPANY_CONTACT_EDIT_FORM_VIEW_ROLE = 'COMPANY_CONTACT_EDIT_FORM_VIEW_ROLE',
  COMPANY_CONTACT_CREATE_FORM_VIEW_ROLE = 'COMPANY_CONTACT_CREATE_FORM_VIEW_ROLE',
  COMPANY_CONTACT_DELETE_FORM_VIEW_ROLE = 'COMPANY_CONTACT_DELETE_FORM_VIEW_ROLE'
}

export enum AV2SitesViewPermissionsEnum {
  SITES_VIEW_ROLE = 'SITES_VIEW_ROLE',
  SITE_OVERVIEW_ROLE = 'SITE_OVERVIEW_ROLE',
  SITE_CREATE_FORM_VIEW_ROLE = 'SITE_CREATE_FORM_VIEW_ROLE',
  SITE_EDIT_FORM_VIEW_ROLE = 'SITE_EDIT_FORM_VIEW_ROLE',
  SITE_DELETE_FORM_VIEW_ROLE = 'SITE_DELETE_FORM_VIEW_ROLE',
  SITE_CONTACTS_VIEW_ROLE = 'SITE_CONTACTS_VIEW_ROLE',
  SITE_CONTACT_OVERVIEW_VIEW_ROLE = 'SITE_CONTACT_OVERVIEW_VIEW_ROLE',
  SITE_CONTACT_EDIT_FORM_VIEW_ROLE = 'SITE_CONTACT_EDIT_FORM_VIEW_ROLE',
  SITE_CONTACT_CREATE_FORM_VIEW_ROLE = 'SITE_CONTACT_CREATE_FORM_VIEW_ROLE',
  SITE_CONTACT_DELETE_FORM_VIEW_ROLE = 'SITE_CONTACT_DELETE_FORM_VIEW_ROLE'
}

export enum AV2TagsViewPermissionsEnum {
  TAGS_VIEW_ROLE = 'TAGS_VIEW_ROLE',
  TAG_OVERVIEW_ROLE = 'TAG_OVERVIEW_ROLE',
  TAG_EDIT_FORM_VIEW_ROLE = 'TAG_EDIT_FORM_VIEW_ROLE',
  TAG_DELETE_FORM_VIEW_ROLE = 'TAG_DELETE_FORM_VIEW_ROLE',
  TAG_CREATE_FORM_VIEW_ROLE = 'TAG_CREATE_FORM_VIEW_ROLE'
}

export enum AV2ClientPricelistsViewPermissionsEnum {
  CLIENT_PRICE_LISTS_VIEW_ROLE = 'CLIENT_PRICE_LISTS_VIEW_ROLE',
}

export enum AV2DistributorViewPermissionsEnum {
  DISTRIBUTOR_DEVICES_VIEW_ROLE = 'DISTRIBUTOR_DEVICES_VIEW_ROLE',
  DISTRIBUTOR_LICENSES_VIEW_ROLE = 'DISTRIBUTOR_LICENSES_VIEW_ROLE',
  DISTRIBUTOR_USERS_VIEW_ROLE = 'DISTRIBUTOR_USERS_VIEW_ROLE'
}

export enum AV2DevicesViewPermissionsEnum {
  DEVICES_VIEW_ROLE = 'DEVICES_VIEW_ROLE',
  DEVICE_OVERVIEW_ROLE = 'DEVICE_OVERVIEW_ROLE',
  DEVICE_EDIT_FORM_VIEW_ROLE = 'DEVICE_EDIT_FORM_VIEW_ROLE',
  DEVICE_DELETE_FORM_VIEW_ROLE = 'DEVICE_DELETE_FORM_VIEW_ROLE',
  DEVICE_CREATE_FORM_VIEW_ROLE = 'DEVICE_CREATE_FORM_VIEW_ROLE',
  DEVICE_ATM_ROLE = 'DEVICE_ATM_ROLE'
}

export enum AV2LicensesViewPermissionsEnum {
  LICENSES_VIEW_ROLE = 'LICENSES_VIEW_ROLE',
  LICENSE_EDIT_FORM_VIEW_ROLE = 'LICENSE_EDIT_FORM_VIEW_ROLE',
  LICENSE_CREATE_FORM_VIEW_ROLE = 'LICENSE_CREATE_FORM_VIEW_ROLE',
  LICENSE_DELETE_FORM_VIEW_ROLE = 'LICENSE_DELETE_FORM_VIEW_ROLE'
}

export enum AV2RoutesViewPermissionsEnum {
  ROUTES_VIEW_ROLE = 'ROUTES_VIEW_ROLE',
  ROUTE_OVERVIEW_VIEW_ROLE = 'ROUTE_OVERVIEW_VIEW_ROLE',
  ROUTE_EDIT_FORM_VIEW_ROLE = 'ROUTE_EDIT_FORM_VIEW_ROLE',
  ROUTE_CREATE_FORM_VIEW_ROLE = 'ROUTE_CREATE_FORM_VIEW_ROLE',
  ROUTE_DELETE_FORM_VIEW_ROLE = 'ROUTE_DELETE_FORM_VIEW_ROLE'
}

export enum AV2TasksViewPermissionsEnum {
  TASKS_VIEW_ROLE = 'TASKS_VIEW_ROLE',
  TASK_OVERVIEW_ROLE = 'TASK_OVERVIEW_ROLE',
  TASK_OVERVIEW_HANDLING_BUTTONS_ROLE = 'TASK_OVERVIEW_HANDLING_BUTTONS_ROLE',
  TASK_CREATE_FORM_VIEW_ROLE = 'TASK_CREATE_FORM_VIEW_ROLE',
  TASK_DELETE_FORM_VIEW_ROLE = 'TASK_DELETE_FORM_VIEW_ROLE'
}

export enum AV2TimetableViewPermissionsEnum {
  TIMETABLE_VIEW_ROLE = 'TIMETABLE_VIEW_ROLE',
  TIMETABLE_EDIT_FORM_VIEW_ROLE = 'TIMETABLE_EDIT_FORM_VIEW_ROLE',
  TIMETABLE_CREATE_FORM_VIEW_ROLE = 'TIMETABLE_CREATE_FORM_VIEW_ROLE',
  TIMETABLE_DELETE_FORM_VIEW_ROLE = 'TIMETABLE_DELETE_FORM_VIEW_ROLE'
}

export enum AV2AlarmsViewRolePermissionsEnum {
  ALARMS_VIEW_ROLE = 'ALARMS_VIEW_ROLE',
  ALARM_OVERVIEW_VIEW_ROLE = 'ALARM_OVERVIEW_VIEW_ROLE',
  ALARM_OVERVIEW_SEND_PATROL_BUTTON_ROLE = 'ALARM_OVERVIEW_SEND_PATROL_BUTTON_ROLE',
  ALARM_OVERVIEW_UPDATE_ROLE = 'ALARM_OVERVIEW_UPDATE_ROLE',
  ALARM_OVERVIEW_ABANDON_BUTTON_ROLE = 'ALARM_OVERVIEW_ABANDON_BUTTON_ROLE'
}

export enum AV2PatrolsViewRolePermissionsEnum {
  PATROLS_VIEW_ROLE = 'PATROLS_VIEW_ROLE'
}

export enum AV2ReportsPermissionsEnum {
  MANUAL_REPORTS_VIEW_ROLE = 'MANUAL_REPORTS_VIEW_ROLE',
  RECURRING_REPORTS_VIEW_ROLE = 'RECURRING_REPORTS_VIEW_ROLE',
  RECURRING_REPORT_CREATE_FORM_VIEW_ROLE = 'RECURRING_REPORT_CREATE_FORM_VIEW_ROLE',
  RECURRING_REPORT_EDIT_FORM_VIEW_ROLE = 'RECURRING_REPORT_EDIT_FORM_VIEW_ROLE',
  RECURRING_REPORT_DELETE_FORM_VIEW_ROLE = 'RECURRING_REPORT_DELETE_FORM_VIEW_ROLE',
  BILLINGS_VIEW_ROLE = 'BILLINGS_VIEW_ROLE',
  REPORTS_TEMPLATE_VIEW_ROLE = 'REPORTS_TEMPLATE_VIEW_ROLE',
  REPORT_TEMPLATE_CREATE_FORM_VIEW_ROLE = 'REPORT_TEMPLATE_CREATE_FORM_VIEW_ROLE',
  REPORT_TEMPLATE_UPDATE_FORM_VIEW_ROLE = 'REPORT_TEMPLATE_UPDATE_FORM_VIEW_ROLE',
  REPORT_TEMPLATE_DELETE_ROLE_VIEW_ROLE = 'REPORT_TEMPLATE_DELETE_ROLE_VIEW_ROLE'
}

export enum AV2EventsViewRolePermissionsEnum {
  EVENTS_VIEW_ROLE = 'EVENTS_VIEW_ROLE'
}

export enum AV2EndUserPermissionsEnum {
  END_USER_ROLE = 'END_USER_ROLE'
}
