import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  Output,
  ViewChild
} from "@angular/core";
import dayjs from "dayjs";
import { CommonModule } from "@angular/common";
import { AV2Calendar, AV2CalendarModule } from "../calendar/calendar";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CoreModule } from "../../../core/core.module";
import { Sidebar, SidebarModule } from "primeng/sidebar";
import { ViewUtils } from "../../helpers/viewUtils";
import { InputTextModule } from "primeng/inputtext";
import { DateRangeEnum, DateUtils, sleep } from "../../helpers/dateUtils";
import { CustomDatePipe } from "../../pipes/customDate.pipe";
import { TranslateModule } from "@ngx-translate/core";

const PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RangePicker),
  multi: true
};

@Component({
  selector: "av2-range-picker",
  template: `
    <ng-container *ngIf="isMobile; else calendar">
      <span class="p-calendar p-calendar-w-btn" [ngClass]="{ 'p-focus': av2Calendar?.focus }">
        <input type="text" [ngModel]="modelForInput" readonly pInputText class="p-inputtext p-component"
               [placeholder]="placeholder" (click)="open = true" />
        <button
          type="button"
          [icon]="'pi pi-calendar'"
          pButton
          pRipple
          (click)="open = true"
          [ngClass]="{ 'av2-calendar-button-hover-focus': av2Calendar?.focus || av2Calendar?.hover }"
          class="p-datepicker-trigger"
          tabindex="0"
        ></button>
      </span>
    </ng-container>
    <p-sidebar #sidebar [(visible)]="open" [fullScreen]="true" [baseZIndex]="10000" (onHide)="sidebar.destroyModal()">
      <ng-container *ngTemplateOutlet="calendar"></ng-container>
    </p-sidebar>

    <ng-template #calendar>
      <av2-calendar
        #av2Calendar
        [disabled]="disabled"
        [(ngModel)]="model"
        [specialPicker]="true"
        selectionMode="range"
        [numberOfMonths]="isMobile ? 1 : 2"
        [withCustomScaleOnTouchUI]="withCustomScaleOnTouchUI"
        [inline]="isMobile"
        [touchUI]="false"
        [appendTo]="appendTo"
        (onClose)="modelUpdateOnCLose(); onClose.emit($event)"
        (onShow)="onShow.emit($event)"
        [placeholder]="placeholder"
        [scale075]="!isMobile"
        [style]="isMobile ? { width: '100vw' } : {}"
      >
        <ng-template pTemplate="header">
          <div class="range-picker-left-container" [ngClass]="{w185: !isMobile}">
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.TODAY }"
              (click)="pick(dateRange.TODAY)"
              [label]="'ui.range-picker.today' | translate"
            ></button>
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.YESTERDAY }"
              (click)="pick(dateRange.YESTERDAY)"
              [label]="'ui.range-picker.yesterday' | translate"
            ></button>
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.CURRENT_WEEK }"
              (click)="pick(dateRange.CURRENT_WEEK)"
              [label]="'ui.range-picker.this-week' | translate"
            ></button>
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.LAST_WEEK }"
              (click)="pick(dateRange.LAST_WEEK)"
              [label]="'ui.range-picker.last-week' | translate"
            ></button>
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.CURRENT_MONTH }"
              (click)="pick(dateRange.CURRENT_MONTH)"
              [label]="'ui.range-picker.this-month' | translate"
            ></button>
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.LAST_MONTH }"
              (click)="pick(dateRange.LAST_MONTH)"
              [label]="'ui.range-picker.last-month' | translate"
            ></button>
            <button
              pButton
              pRipple
              class="submit-button range-picker-days-button p-button-outlined av2-link-button-days"
              [ngClass]="{ active: actived === dateRange.LAST_DAYS }"
              (click)="pick(dateRange.LAST_DAYS)"
              [label]="'ui.range-picker.last-days' | translate"
            ></button>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="range-picker-hour">
            <div style="width: 50%;" class="hour-border">
              <ng-container *ngIf="!!model && !!model[0]">
                <label>{{"ui.range-picker.start" | translate}}</label>
                <p class="av2-text-bold-1">{{ model[0] | customDate }}</p>
                <label>{{"ui.range-picker.hour" | translate}}</label>
                <av2-calendar [inline]="true" [timeOnly]="true" [touchUI]="false" [ngModel]="model[0]"
                              (ngModelChange)="modelChange(0, $event)" [showSeconds]="true"></av2-calendar>
              </ng-container>
            </div>
            <div style="width: 50%; border: none">
              <ng-container *ngIf="!!model && !!model[1]">
                <label>{{"ui.range-picker.end" | translate}}</label>
                <p class="av2-text-bold-1">{{ model[1] | customDate }}</p>
                <label>{{"ui.range-picker.hour" | translate}}</label>
                <av2-calendar [inline]="true" [timeOnly]="true" [touchUI]="false" [ngModel]="model[1]"
                              (ngModelChange)="modelChange(1, $event)" [showSeconds]="true"></av2-calendar>
              </ng-container>
            </div>
          </div>
          <div class="range-picker-submit-button">
            <button pButton pRipple class="submit-button av2-range-picker-button" type="button" (click)="clear()"
                    [label]="'ui.range-picker.clear' | translate"></button>
            <button pButton pRipple class="submit-button av2-range-picker-button" [disabled]="!isValid" type="button"
                    (click)="submit()" [label]="'ui.range-picker.submit' | translate"></button>
          </div>
        </ng-template>
      </av2-calendar>
    </ng-template>
  `,
  providers: [PICKER_VALUE_ACCESSOR],
  styleUrls: ["./range-picker.css"]
})
export class RangePicker implements ControlValueAccessor {
  @Input() appendTo: any;
  @Input() withCustomScaleOnTouchUI = false;
  @Input() placeholder: string = ""
  @Input() disabled: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();

  @ViewChild("av2Calendar") av2Calendar?: AV2Calendar;
  @ViewChild("sidebar") sidebar?: Sidebar;

  constructor(public cd: ChangeDetectorRef, private datePipe: CustomDatePipe) {
  }

  open = false;
  model: Date[] = [];
  now = dayjs().setAppTime(12, 0, 0);
  dateRange = DateRangeEnum;

  actived: DateRangeEnum | null = null;

  get modelForInput(): string {
    let s = "";
    if (!this.model || this.model.length < 2) return s;
    s += this.datePipe.transform(this.model[0], "TIME") + " - " + this.datePipe.transform(this.model[1], "TIME");
    return s;
  }

  get isMobile(): boolean {
    return ViewUtils.isMobileView() || ViewUtils.isTabletView();
  }

  get isValid(): boolean {
    return !!this.model && this.model.length === 2 && this.model.every((x) => x !== undefined && x !== null);
  }

  modelChange(index: number, model: Date) {
    sleep(1).then(() => {
      this.model[index] = model;
      const tmp = [...this.model];
      sleep(1).then(() => {
        this.model = tmp;
      });
    });
  }

  modelUpdateOnCLose() {
    if (!this.model || this.model.length < 2 || !(this.model[1] instanceof Date)) {
      this.model = [];
    }
    this.onModelChange(this.model);
  }

  onModelChange: Function = () => {
  };

  onModelTouched: Function = () => {
  };

  writeValue(obj: Date[]): void {
    this.model = obj;
    this.cd.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  submit(): void {
    this.open = false;
    !this.isMobile && this.av2Calendar?.hideOverlay();
  }

  clear(): void {
    this.model = [];
    this.onModelChange([]);
    this.open = false;
    this.actived = null;
  }

  pick(range: DateRangeEnum): void {
    if (range === DateRangeEnum.LAST_DAYS) this.model = DateUtils.range().LAST_DAYS(30);
    else this.model = DateUtils.range()[range];
    this.actived = range;
  }
}

@NgModule({
  imports: [CommonModule, AV2CalendarModule, FormsModule, CoreModule, SidebarModule, InputTextModule, TranslateModule],
  exports: [RangePicker],
  declarations: [RangePicker]
})
export class AV2RangePickerModule {
}
