import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AV2ListDataSortDirection, AV2ListDataSortRequest } from 'src/app/commons/models/data-list';


export abstract class SortBaseComponent {

  AV2ListDataSortDirection = AV2ListDataSortDirection;
  sortConfig: AV2ListDataSortRequest[] = [];
  selectedFilterColumn: string = '';
  loading$!: Observable<boolean>;

  protected constructor(protected store$: Store) {
    this.loading$ = of(true);
  }

  sortData(columnName: string, callback: (sortData: AV2ListDataSortRequest) => void) {
    this.selectedFilterColumn = columnName;
    const foundSortItem = this.sortConfig.find(s => s.sort === columnName);
    if (foundSortItem) {
      if (foundSortItem.direction === AV2ListDataSortDirection.ANY) {
        foundSortItem.direction = AV2ListDataSortDirection.ASC;
      } else if (foundSortItem.direction === AV2ListDataSortDirection.ASC) {
        foundSortItem.direction = AV2ListDataSortDirection.DESC;
      } else {
        foundSortItem.direction = AV2ListDataSortDirection.ANY;
      }
      const sortData: AV2ListDataSortRequest = { sort: columnName, direction: foundSortItem.direction };
      if (!foundSortItem.disabledSort) {
        callback && callback(sortData);
      }
    }
  }

}
